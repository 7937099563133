"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isTypedArray = void 0;
exports.getTag = getTag;
exports.isObjectLike = isObjectLike;
exports.isArguments = isArguments;
exports.isArrayLike = isArrayLike;
exports.isPrototype = isPrototype;
exports.isLength = isLength;
const nodeTypes_1 = require("./nodeTypes");
const nodeIsTypedArray = nodeTypes_1.nodeTypes && nodeTypes_1.nodeTypes.isTypedArray;
const _toString = Object.prototype.toString;
const objectProto = Object.prototype;
const MAX_SAFE_INTEGER = 900719925474099;
/** Used to match `toStringTag` values of typed arrays. */
// @ts-ignore
const reTypedTag = /^\[object (?:Float(?:32|64)|(?:Int|Uint)(?:8|16|32)|Uint8Clamped)Array\]$/;
function getTag(value) {
    if (value == null) {
        return value === undefined ? '[object Undefined]' : '[object Null]';
    }
    return _toString.call(value);
}
/**
 * Checks if `value` is object-like. A value is object-like if it's not `null`
 * and has a `typeof` result of "object".
 *
 * @example
 *
 * isObjectLike({})
 * // => true
 *
 * isObjectLike([1, 2, 3])
 * // => true
 *
 * isObjectLike(Function)
 * // => false
 *
 * isObjectLike(null)
 * // => false
 */
function isObjectLike(value) {
    return typeof value === 'object' && value != null;
}
/**
 * Checks if `value` is likely an `arguments` object.
 *
 * @example
 *
 * isArguments(function() { return arguments }())
 * // => true
 *
 * isArguments([1, 2, 3])
 * // => false
 */
function isArguments(value) {
    return isObjectLike(value) && getTag(value) == '[object Arguments]';
}
/**
 * Checks if `value` is array-like. A value is considered array-like if it's
 * not a function and has a `value.length` that's an integer greater than or
 * equal to `0` and less than or equal to `Number.MAX_SAFE_INTEGER`.
 *
 * @example
 *
 * isArrayLike([1, 2, 3])
 * // => true
 *
 * isArrayLike(document.body.children)
 * // => true
 *
 * isArrayLike('abc')
 * // => true
 *
 * isArrayLike(Function)
 * // => false
 */
function isArrayLike(value) {
    return value != null && typeof value != 'function' && isLength(value.length);
}
/**
 * Checks if `value` is likely a prototype object.
 */
function isPrototype(value) {
    const Ctor = value && value.constructor;
    const proto = (typeof Ctor == 'function' && Ctor.prototype) || objectProto;
    return value === proto;
}
/**
 * Checks if `value` is a valid array-like length.
 *
 * **Note:** This method is loosely based on
 * [`ToLength`](http://ecma-international.org/ecma-262/7.0/#sec-tolength).
 *
 * @example
 *
 * isLength(3)
 * // => true
 *
 * isLength(Number.MIN_VALUE)
 * // => false
 *
 * isLength(Infinity)
 * // => false
 *
 * isLength('3')
 * // => false
 */
function isLength(value) {
    return typeof value === 'number' && value > -1 && value % 1 == 0 && value <= MAX_SAFE_INTEGER;
}
/**
 * Checks if `value` is classified as a typed array.
 *
 * @example
 *
 * isTypedArray(new Uint8Array)
 * // => true
 *
 * isTypedArray([])
 * // => false
 */
exports.isTypedArray = nodeIsTypedArray
    ? (value) => nodeIsTypedArray(value)
    : (value) => isObjectLike(value) && reTypedTag.test(getTag(value));
