"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isDate = void 0;
exports.isString = isString;
exports.isNumber = isNumber;
exports._isFinite = _isFinite;
exports.isBoolean = isBoolean;
exports.isObject = isObject;
exports.isFunction = isFunction;
exports.isNil = isNil;
exports.isEmpty = isEmpty;
const nodeTypes_1 = require("./nodeTypes");
const utils_1 = require("./utils");
const nodeIsDate = nodeTypes_1.nodeTypes && nodeTypes_1.nodeTypes.isDate;
function _isBuffer(value) {
    return typeof Buffer !== 'undefined' && Buffer.isBuffer(value);
}
function isString(value) {
    const type = typeof value;
    return (type === 'string' ||
        (type === 'object' &&
            value != null &&
            !Array.isArray(value) &&
            (0, utils_1.getTag)(value) == '[object String]'));
}
function isNumber(value) {
    return typeof value === 'number' || ((0, utils_1.isObjectLike)(value) && (0, utils_1.getTag)(value) === '[object Number]');
}
function _isFinite(value) {
    return Number.isFinite(value);
}
exports.isDate = nodeIsDate
    ? (value) => nodeIsDate(value)
    : (value) => (0, utils_1.isObjectLike)(value) && (0, utils_1.getTag)(value) == '[object Date]';
function isBoolean(value) {
    return (value === true ||
        value === false ||
        ((0, utils_1.isObjectLike)(value) && (0, utils_1.getTag)(value) == '[object Boolean]'));
}
/**
 * Checks if `value` is the
 * [language type](http://www.ecma-international.org/ecma-262/7.0/#sec-ecmascript-language-types)
 * of `Object`. (e.g. arrays, functions, objects, regexes, `new Number(0)`, and `new String('')`)
 */
function isObject(value) {
    const type = typeof value;
    return value !== null && (type === 'object' || type === 'function');
}
function isFunction(value) {
    if (!isObject(value)) {
        return false;
    }
    // The use of `Object#toString` avoids issues with the `typeof` operator
    // in Safari 9 which returns 'object' for typed arrays and other constructors.
    const tag = (0, utils_1.getTag)(value);
    return (tag === '[object Function]' ||
        tag === '[object AsyncFunction]' ||
        tag === '[object GeneratorFunction]' ||
        tag === '[object Proxy]');
}
function isNil(value) {
    return value == null;
}
const _hasOwnProperty = Object.prototype.hasOwnProperty;
/**
 * Checks if `value` is a nil value, empty object, collection, map, or set.
 *
 * Objects are considered empty if they have no own enumerable string keyed
 * properties.
 *
 * Array-like values such as `arguments` objects, arrays, buffers, strings, or
 * Similarly, maps and sets are considered empty if they have a `size` of `0`.
 * Numbers and booleans are never empty
 */
function isEmpty(value) {
    if (isNil(value)) {
        return true;
    }
    if (isNumber(value) || isBoolean(value)) {
        return false;
    }
    if ((0, utils_1.isArrayLike)(value) &&
        (Array.isArray(value) ||
            typeof value === 'string' ||
            typeof value.splice === 'function' ||
            _isBuffer(value) ||
            (0, utils_1.isTypedArray)(value) ||
            (0, utils_1.isArguments)(value))) {
        return !value.length;
    }
    const tag = (0, utils_1.getTag)(value);
    if (tag === '[object Map]' || tag === '[object Set]') {
        return !value.size;
    }
    if ((0, utils_1.isPrototype)(value)) {
        return !Object.keys(value).length;
    }
    for (const key in value) {
        if (_hasOwnProperty.call(value, key)) {
            return false;
        }
    }
    return true;
}
