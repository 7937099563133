var __defProp = Object.defineProperty;
var __name = (target, value) => __defProp(target, "name", { value, configurable: true });

// lib/validation.helper.ts
import { isBoolean, isDate, isEmpty, isFunction, isNil, isObject, isString } from "zoology";

// lib/ValidationError.ts
var ValidationError = class extends Error {
  static {
    __name(this, "ValidationError");
  }
  isValidationError = "true";
};

// lib/validation.helper.ts
function validateNotNil(validatedObject, errorText) {
  if (isNil(validatedObject)) {
    throw new ValidationError(errorText || "Validated object is null or undefined");
  }
  return validatedObject;
}
__name(validateNotNil, "validateNotNil");
function validateNil(validatedObject, errorText) {
  if (!isNil(validatedObject)) {
    throw new ValidationError(errorText || "Validated object is not null or undefined");
  }
  return validatedObject;
}
__name(validateNil, "validateNil");
function validateNotEmpty(validatedObject, errorText) {
  if (isEmpty(validatedObject)) {
    throw new ValidationError(errorText || "Validated object is empty");
  }
  return validatedObject;
}
__name(validateNotEmpty, "validateNotEmpty");
function validateNumber(validatedObject, errorText) {
  if (!Number.isFinite(validatedObject)) {
    throw new ValidationError(errorText || "Validated object is not a number");
  }
  return validatedObject;
}
__name(validateNumber, "validateNumber");
function validatePositiveNumber(validatedObject, errorText) {
  validateNumber(validatedObject);
  if (validatedObject <= 0) {
    throw new ValidationError(errorText || "Validated number is not positive");
  }
  return validatedObject;
}
__name(validatePositiveNumber, "validatePositiveNumber");
function validateLessThan(validatedObject, threshold, errorText) {
  validateNumber(validatedObject);
  validateNumber(threshold, "Threshold is not a number");
  if (validatedObject >= threshold) {
    throw new ValidationError(
      errorText || `Validated number ${validatedObject} is not less than the threshold ${threshold}`
    );
  }
  return validatedObject;
}
__name(validateLessThan, "validateLessThan");
function validateGreaterThan(validatedObject, threshold, errorText) {
  validateNumber(validatedObject);
  validateNumber(threshold, "Threshold is not a number");
  if (validatedObject <= threshold) {
    throw new ValidationError(
      errorText || `Validated number ${validatedObject} is not greater than the threshold ${threshold}`
    );
  }
  return validatedObject;
}
__name(validateGreaterThan, "validateGreaterThan");
function validateEqual(validatedEntity, expectedEqualTo, errorText) {
  if (validatedEntity !== expectedEqualTo) {
    throw new ValidationError(
      errorText || `Validated entity ${validatedEntity} is not equal to ${expectedEqualTo}`
    );
  }
  return validatedEntity;
}
__name(validateEqual, "validateEqual");
function validateEqualArrays(validatedEntity, expectedEqualTo, errorText) {
  const isEqual = Array.isArray(validatedEntity) && Array.isArray(expectedEqualTo) && validatedEntity.length === expectedEqualTo.length && validatedEntity.every((val, index) => val === expectedEqualTo[index]);
  if (!isEqual) {
    throw new ValidationError(
      errorText || `Validated array ${validatedEntity} is not equal to ${expectedEqualTo}`
    );
  }
  return validatedEntity;
}
__name(validateEqualArrays, "validateEqualArrays");
function validateOneOf(validatedEntity, expectedOneOfEntities, errorText) {
  const index = expectedOneOfEntities.indexOf(validatedEntity);
  if (index === -1) {
    throw new ValidationError(
      errorText || `Validated entity ${validatedEntity} is not one of ${expectedOneOfEntities}`
    );
  }
  return validatedEntity;
}
__name(validateOneOf, "validateOneOf");
function validateSomeNotNil(validatedEntities, errorText) {
  const someAreNotNil = validatedEntities.some((entity) => !isNil(entity));
  if (!someAreNotNil) {
    throw new ValidationError(errorText || "All of validated values are nil");
  }
  return validatedEntities;
}
__name(validateSomeNotNil, "validateSomeNotNil");
function validateNegativeNumber(validatedObject, errorText) {
  validateNumber(validatedObject);
  if (validatedObject >= 0) {
    throw new ValidationError(errorText || "Validated number is not negative");
  }
  return validatedObject;
}
__name(validateNegativeNumber, "validateNegativeNumber");
function validateString(validatedObject, errorText) {
  if (!isString(validatedObject)) {
    throw new ValidationError(errorText || "Validated object is not a string");
  }
  return validatedObject;
}
__name(validateString, "validateString");
function validateBooleanTrue(validatedObject, errorText) {
  if (!isBoolean(validatedObject) || !validatedObject) {
    throw new ValidationError(errorText || "Validated object is not True");
  }
  return validatedObject;
}
__name(validateBooleanTrue, "validateBooleanTrue");
function validateDate(validatedEntity, errorText) {
  if (!isDate(validatedEntity)) {
    throw new ValidationError(errorText || "Validated object is not Date");
  }
  return validatedEntity;
}
__name(validateDate, "validateDate");
function validateBooleanNonStrict(validatedObject, errorText) {
  if (!isBoolean(validatedObject)) {
    if (!isString(validatedObject) || validatedObject.toLowerCase() !== "false" && validatedObject.toLowerCase() !== "true") {
      throw new ValidationError(errorText || "Validated object is not Boolean");
    }
  }
  return validatedObject;
}
__name(validateBooleanNonStrict, "validateBooleanNonStrict");
function validateBoolean(validatedObject, errorText) {
  if (!isBoolean(validatedObject)) {
    throw new ValidationError(errorText || "Validated object is not Boolean");
  }
  return validatedObject;
}
__name(validateBoolean, "validateBoolean");
function validateBooleanFalse(validatedObject, errorText) {
  if (!isBoolean(validatedObject) || validatedObject) {
    throw new ValidationError(errorText || "Validated object is not False");
  }
  return validatedObject;
}
__name(validateBooleanFalse, "validateBooleanFalse");
function validateTruthy(validatedObject, errorText) {
  if (!validatedObject) {
    throw new ValidationError(errorText || "Validated object is not truthy");
  }
  return validatedObject;
}
__name(validateTruthy, "validateTruthy");
function validateFalsy(validatedObject, errorText) {
  if (validatedObject) {
    throw new ValidationError(errorText || "Validated object is not falsy");
  }
  return validatedObject;
}
__name(validateFalsy, "validateFalsy");
function validateFunction(validatedEntity, errorText) {
  if (!isFunction(validatedEntity)) {
    throw new ValidationError(errorText || "Validated entity is not a function");
  }
  return validatedEntity;
}
__name(validateFunction, "validateFunction");
function validateHasProperties(validatedObject, validatedProperties, errorMessage) {
  validateNotNil(validatedObject);
  const undefinedProperties = validatedProperties.filter((property) => {
    return !Object.prototype.hasOwnProperty.call(validatedObject, property);
  });
  if (undefinedProperties.length > 0) {
    throw new ValidationError(
      `${errorMessage ?? "Validated object doesn't have properties: "}${undefinedProperties}`
    );
  }
  return validatedObject;
}
__name(validateHasProperties, "validateHasProperties");
function validateNotNilProperties(validatedObject, validatedProperties, errorMessage) {
  validateNotNil(validatedObject);
  const nilProperties = validatedProperties.filter((property) => {
    return isNil(validatedObject[property]);
  });
  if (nilProperties.length > 0) {
    throw new ValidationError(
      `${errorMessage ?? "Validated object has nil properties: "}${nilProperties}`
    );
  }
  return validatedObject;
}
__name(validateNotNilProperties, "validateNotNilProperties");
function validateObject(validatedObject, errorText) {
  if (!isObject(validatedObject)) {
    throw new ValidationError(errorText || "Validated object is not an object");
  }
  return validatedObject;
}
__name(validateObject, "validateObject");
function validateArray(validatedObject, errorText) {
  if (!Array.isArray(validatedObject)) {
    throw new ValidationError(errorText || "Validated entity is not an array");
  }
  return validatedObject;
}
__name(validateArray, "validateArray");
function validateInstanceOf(validatedObject, expectedClass, errorText) {
  if (!(validatedObject instanceof expectedClass)) {
    throw new ValidationError(
      errorText || `Validated object is not an instance of ${expectedClass.name}`
    );
  }
  return validatedObject;
}
__name(validateInstanceOf, "validateInstanceOf");
function validateInheritsFrom(validatedClass, expectedParentClass, errorText) {
  if (
    //fail-fast if it is nil
    !validatedClass || //lenient check whether class directly or indirectly inherits from expected class
    !(validatedClass.prototype instanceof expectedParentClass) && validatedClass !== expectedParentClass
  ) {
    throw new ValidationError(
      errorText || `Validated class does not inherit from ${expectedParentClass.name}`
    );
  }
  return validatedClass;
}
__name(validateInheritsFrom, "validateInheritsFrom");
export {
  ValidationError,
  validateArray,
  validateBoolean,
  validateBooleanFalse,
  validateBooleanNonStrict,
  validateBooleanTrue,
  validateDate,
  validateEqual,
  validateEqualArrays,
  validateFalsy,
  validateFunction,
  validateGreaterThan,
  validateHasProperties,
  validateInheritsFrom,
  validateInstanceOf,
  validateLessThan,
  validateNegativeNumber,
  validateNil,
  validateNotEmpty,
  validateNotNil,
  validateNotNilProperties,
  validateNumber,
  validateObject,
  validateOneOf,
  validatePositiveNumber,
  validateSomeNotNil,
  validateString,
  validateTruthy
};
