"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isString = exports.isObject = exports.isNumber = exports.isNil = exports.isFunction = exports.isEmpty = exports.isDate = exports.isBoolean = exports.isFinite = void 0;
var zoology_1 = require("./lib/zoology");
Object.defineProperty(exports, "isFinite", { enumerable: true, get: function () { return zoology_1._isFinite; } });
Object.defineProperty(exports, "isBoolean", { enumerable: true, get: function () { return zoology_1.isBoolean; } });
Object.defineProperty(exports, "isDate", { enumerable: true, get: function () { return zoology_1.isDate; } });
Object.defineProperty(exports, "isEmpty", { enumerable: true, get: function () { return zoology_1.isEmpty; } });
Object.defineProperty(exports, "isFunction", { enumerable: true, get: function () { return zoology_1.isFunction; } });
Object.defineProperty(exports, "isNil", { enumerable: true, get: function () { return zoology_1.isNil; } });
Object.defineProperty(exports, "isNumber", { enumerable: true, get: function () { return zoology_1.isNumber; } });
Object.defineProperty(exports, "isObject", { enumerable: true, get: function () { return zoology_1.isObject; } });
Object.defineProperty(exports, "isString", { enumerable: true, get: function () { return zoology_1.isString; } });
