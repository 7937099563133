export const DepthRegistry = {
  BOARD_BACKGROUND: 30,
  ZONE_HIGHLIGHT: 40,
  HOMUNCULUS: 90,
  CARD_MIN: 100,
  CARD_MAX: 300,
  CARD_CHAT_BUBBLE: 400,
  EXPLOSION: 800,
  EVENT: 1000,
  GAME_OVER: 10000,
  INPUT_BLOCK: 9000,
}
